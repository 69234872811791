/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
    props: {
      item: Object,
    },
    computed: {
      name() {
        if (!this.item) return "";
        return this.item.name;
      },
      created_at() {
        if (!this.item) return "";
        return this.$helper.formatDate(this, this.item.created_at)
      },
      content() {
        if (!this.item) return "";
        return this.item.content;
      },
      code() {
        if (!this.item) return "";
        return this.item.code;
      },
      site_id() {
        if (!this.item) return "";
        return this.item.site_id;
      },
      to() {
        //TODO
        return ''
      },
      categories() {
        if (!this.item) return [];
        if (!this.item.category) return [];
        this.item.category.sort((a, b) => {
          a.sort - b.sort;
        });
  
        return this.item.category;
      },
      category_name() {
        if (!this.item) return "";
        if (_isEmpty(this.categories)) return "";
        return this.categories[0].category.name;
      },
      photoUrl() {
        if (!this.item) return "";
        return this.$helper.getPhotoUrl(this.item.photo)
      },
      photoName() {
        if (!this.item) return "";
        return this.$helper.getPhotoName(this.item.photo)
      },
    },
  };
  